//utils/global-util.js
import {getUUID} from "@/utils/index";

/**
 * 替换掉所有的 html标签，得到Html标签中的内容
 * @param info
 * @returns {*}
 */
export function replaceHtmlTag(info) {
    let dd = info.replace(/<\/?.+?>/g, "");
    return dd.replace(/ /g, "");//去除一些空格
}

export default {
    isNull(o) {
        return !o
    },
    isNullOrEmpty(str) {
        return !str || str.trim() === ''
    },
    getNameById(id, list) {
        if (!list) return null;
        let str = '';
        for (let i = 0, len = list.length; i < len; i++) {
            const l = list[i];
            if (l.id === id) {
                str = l.name;
                break;
            }
        }
        return str;
    },
    //在mixins之中也存在，但是没有必要在mixins之中使用；
    getLimitText(text, num) {
        if (!num) {
            num = 20;
        }
        if (text && text.length > num) {
            return text.slice(0, num) + '…'
        } else if (text && text.length > 0) {
            return text;
        }
        return '无';
    },
    uuid() {
        return getUUID()
    },
    /**
     * 在列表之中查找值
     * @param list 列表信息
     * @param cmpKey 需要比较的key
     * @param cmpVal 需要匹配的值
     * @param targetKey 获取目标的值得key
     * @returns {null}
     */
    showText(list, cmpKey, cmpVal, targetKey) {
        if (!list) {
            return null;
        }
        let result = null;
        for (let i = 0, len = list.length; i < len; i++) {
            const item = list[i];
            if (item[cmpKey] === cmpVal) {
                result = item[targetKey]
                break;
            }
        }
        return result;

    },
    findParam: function (key) {
        return decodeURIComponent((new RegExp('[?|&]' + key + '=' + '([^&;]+?)(&|#|;|$)')
            .exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null;
    },
    isMobile() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    },
    /**
     * 时间格式化处理
     * author: meizz
     */
    dateFormat(fmt, date) {
        if (!date) {
            return null;
        }
        if (typeof date === 'string' || typeof date === 'number') {
            date = new Date(date);
        }
        const o = {
            "M+": date.getMonth() + 1,                 //月份
            "d+": date.getDate(),                    //日
            "h+": date.getHours(),                   //小时
            "m+": date.getMinutes(),                 //分
            "s+": date.getSeconds(),                 //秒
            "q+": Math.floor((date.getMonth() + 3) / 3), //季度
            "S": date.getMilliseconds()             //毫秒
        };
        if (/(y+)/.test(fmt))
            fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
        for (let k in o)
            if (new RegExp("(" + k + ")").test(fmt))
                fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        return fmt;
    },
    //获取两个数字之间的数据
    getNumBetween(start, end) {
        if (!start instanceof Number) {
            start = parseInt(start);
        }
        if (!end instanceof Number) {
            end = parseInt(end);
        }
        if (end < start) {
            let tmp = start;
            start = end;
            end = tmp;
        }
        //初始化日期列表，数组
        const diffNums = new Array();
        let i = 0;
        while (start <= end) {
            diffNums[i++] = start++;
        }
        return diffNums
    }

}
