import Vue from 'vue'
import cloneDeep from "lodash/cloneDeep";

import App from './App.vue'
import './plugins'
import '@/assets/scss/index.scss'
import router from '@/router'
import store from '@/store'
import {ENV} from "./config/constants";

Vue.config.productionTip = false

// 保存整站vuex本地储存初始状态，需要在前端定义全局变量window.SITE_CONFIG，在plugins/env下面
window.SITE_CONFIG['storeState'] = cloneDeep(store.state)

// 非生产环境, 适配mockjs模拟数据
// api: https://github.com/nuysoft/Mock
if (process.env.NODE_ENV !== ENV.pro) {
  require('@/mock')
}

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
