//store/index.js
// api: https://github.com/vuejs/vuex
import Vue from 'vue'
import Vuex from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import common from './modules/common'
import user from './modules/user'
import wechat from './modules/wechat'

Vue.use(Vuex)

export default new Vuex.Store({
    getters: {
        // eslint-disable-next-line
        serveTypeList: state => wechat.state.serveTypeList,
        // eslint-disable-next-line
        objTypeList: state => wechat.state.objTypeList,
    },
    modules: {
        common,
        user,
        wechat
    },
    mutations: {
        // 重置vuex本地储存状态
        resetStore(state) {
            Object.keys(state).forEach((key) => {
                state[key] = cloneDeep(window.SITE_CONFIG['storeState'][key])
            })
        }
    },
    strict: process.env.NODE_ENV !== 'production'
})
