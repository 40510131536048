import config from "@/config";

/**
 *  进行一些基础环境配置，原来的是位于static目录下，直接加载在index.html上的，这里不再需要，根据环境的不同，从config里面读取api信息
 */
// eslint-disable-next-line
console.log(`Your Config Environment IS '${config.env}'`)

;(function () {
    window.SITE_CONFIG = {};

    // api接口请求地址
    window.SITE_CONFIG['baseUrl'] = config.api

    // cdn地址 = 域名 + 版本号
    window.SITE_CONFIG['domain'] = './'; // 域名
    window.SITE_CONFIG['version'] = '';   // 版本号(年月日时分)
    window.SITE_CONFIG['cdnUrl'] = window.SITE_CONFIG.domain + window.SITE_CONFIG.version;
})();
