//store/modules/wechat.js
import httpRequest from '../../utils/httpRequest'

export default {
    namespaced: true,
    state: {
        serveTypeList: [],
        objTypeList: [],
    },
    mutations: {
        updateServeTypeList(state, list) {
            state.serveTypeList = list
        },
        updateObjTypeList(state, list) {
            state.objTypeList = list
        }
    },
    actions: {
        getServeTypeList(context) {
            let storedList = localStorage.getItem("serveTypes")
            if (!storedList || storedList.length === 0) {
                httpRequest({
                    url: httpRequest.adornUrl('/wechat/servetype/list'),
                    method: 'get',
                    params: httpRequest.adornParams({
                        'limit': 100,
                    })
                }).then(({data}) => {
                    storedList = data.page.list
                    context.commit('updateServeTypeList', storedList)
                    localStorage.setItem("serveTypes", JSON.stringify(storedList))
                })
            } else {
                context.commit('updateServeTypeList', JSON.parse(storedList))
            }

        },
        getObjTypeList(context) {
            let storedList = localStorage.getItem("objectTypes")
            if (!storedList || storedList.length === 0) {
                httpRequest({
                    url: httpRequest.adornUrl('/wechat/objecttype/list'),
                    method: 'get',
                    params: httpRequest.adornParams({
                        'limit': 100,
                    })
                }).then(({data}) => {
                    storedList = data.page.list
                    context.commit('updateObjTypeList', storedList)
                    localStorage.setItem("objectTypes", JSON.stringify(storedList))
                })
            } else {
                context.commit('updateObjTypeList', JSON.parse(storedList))
            }
        }
    }
}
