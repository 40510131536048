/**
 * 字体图标, 统一使用SVG Sprite矢量图标(http://www.iconfont.cn/）
 *
 * 使用:
 *  1. 在阿里矢量图标站创建一个项目, 并添加图标(这一步非必须, 创建方便项目图标管理)
 *  2-1. 添加icon, 选中新增的icon图标, 复制代码 -> 下载 -> SVG下载 -> 粘贴代码(重命名)
 *  2-2. 添加icons, 下载图标库对应[iconfont.js]文件, 替换项目[./iconfont.js]文件
 *  3. 组件模版中使用 [<icon-svg name="canyin"></icon-svg>]
 *
 * 注意:
 *  1. 通过2-2 添加icons, getNameList方法无法返回对应数据
 */
// api: http://www.iconfont.cn/
import Vue from 'vue'
import IconSvg from '@/components/icon-svg/Icon'
import './iconfont.js'

Vue.component('IconSvg', IconSvg)
// require.context无法使用变量，所以无法导入config之中的变量，无法达到和Icon里面的统一
const svgFiles = require.context(`@/plugins/icons/svg`, true, /\.svg$/)
const iconList = svgFiles.keys().map(item => {
    return svgFiles(item)
})
export default {
    // 获取图标icon-(*).svg名称列表, 例如[shouye, xitong, zhedie, ...]
    getNameList() {
        return iconList.map(item => {
            // return item.default.id.replace('icon-', '');
            const nameHash = item.replace('/img/icon-', '').replace('.svg', '');
            let nameHashDotIndex = nameHash.lastIndexOf('.');
            return nameHash.substring(0, nameHashDotIndex);
        })
    }
}
