export const ENV = {
    'dev': 'development',
    "pro": 'production',
    "test": 'test'
}

export const BASE_API_URL = {
    dev: 'http://localhost:9998',
    pro: 'https://api.daaooo.com'
}

export const REQUEST = {
    timeout: 40000
}
/**
 * Http请求方法
 * @type {{HEAD: string, DELETE: string, POST: string, GET: string, PUT: string, PATCH: string}}
 */
export const HTTP_METHODS = {
    GET: 'get',
    POST: 'post',
    DELETE: 'delete',
    PUT: 'put',
    PATCH: 'patch',
    OPTIONS: 'options',
    HEAD: 'head'
}

