//router/index.js
/**
 * // api: https://github.com/vuejs/vue-router
 * 全站路由配置
 * 建议:
 * 1. 代码中路由统一使用name属性跳转(不使用path属性)
 */
import Vue from 'vue'
import Router from 'vue-router'
import {globalRoutes, mainRoutes} from "@/router/routes";
import {fnCurrentRouteType, handleDynamicRoutes} from "@/router/route-util";
import config from "../config";

Vue.use(Router)

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const router = new Router({
    mode: 'hash',
    scrollBehavior: () => ({y: 0}),
    // 是否已经添加动态(菜单)路由
    isAddDynamicMenuRoutes: false,
    routes: globalRoutes.concat(mainRoutes)
})

router.beforeEach((to, from, next) => {
    let title = to.meta ? to.meta.title : null;
    title = title ? title + '-' + config.title : config.title;
    window.document.title = title;
    // 添加动态(菜单)路由
    // 1. 已经添加 or 全局路由, 直接访问
    // 2. 获取菜单列表, 添加并保存本地存储
    if (router.options.isAddDynamicMenuRoutes
        || fnCurrentRouteType(to, globalRoutes) === 'global') {
        next()
    } else {
        handleDynamicRoutes(router, to, next);
    }
})


export default router
