//router/route-util.js
import {isURL} from "@/utils/validate";
import {mainRoutes, ROUTE_TYPES} from "@/router/routes";
import router from "@/router/index";
import http from '@/utils/httpRequest'
import {HTTP_METHODS} from "@/config/constants";

/**
 * 开发环境不使用懒加载, 因为懒加载页面太多的话会造成webpack热更新太慢,
 * 所以只有生产环境使用懒加载
 * @private
 */
export const _import = require('./import-' + process.env.NODE_ENV)

/**
 * 请求获取动态菜单并进行初始化处理
 */
export function handleDynamicRoutes(router, to, next) {
    http({
        url: http.adornUrl('/sys/menu/nav'),
        method: HTTP_METHODS.GET,
        params: http.adornParams()
    }).then(({data}) => {
        if (data && data.code === 0) {
            fnAddDynamicMenuRoutes(data.menuList)
            router.options.isAddDynamicMenuRoutes = true
            sessionStorage.setItem('menuList', JSON.stringify(data.menuList || '[]'))
            sessionStorage.setItem('permissions', JSON.stringify(data.permissions || '[]'))
            next({...to, replace: true})
        } else {
            sessionStorage.setItem('menuList', '[]')
            sessionStorage.setItem('permissions', '[]')
            next()
        }
    }).catch((e) => {
        // eslint-disable-next-line
        console.log(`%c${e} 请求菜单列表和权限失败，跳转至登录页！！`, 'color:blue')
        router.push({name: 'login'})
    })
}

/**
 * 判断当前路由类型, global: 全局路由, main: 主入口路由
 * @param {*} route 当前路由
 * @param {*} globalRoutes 全局路由
 */
export function fnCurrentRouteType(route, globalRoutes = []) {
    let temp = []
    for (let i = 0; i < globalRoutes.length; i++) {
        if (route.path === globalRoutes[i].path) {
            return ROUTE_TYPES.GLOBAL
        } else if (globalRoutes[i].children && globalRoutes[i].children.length >= 1) {
            temp = temp.concat(globalRoutes[i].children)
        }
    }
    return temp.length >= 1 ? fnCurrentRouteType(route, temp) : ROUTE_TYPES.MAIN
}

/**
 * 添加动态(菜单)路由
 * @param {*} menuList 菜单列表
 * @param {*} routes 递归创建的动态(菜单)路由
 */
export function fnAddDynamicMenuRoutes(menuList = [], routes = []) {
    let temp = []
    for (let i = 0; i < menuList.length; i++) {
        const item = menuList[i];
        if (item.list && item.list.length >= 1) {
            temp = temp.concat(item.list)
        } else if (item.url && /\S/.test(item.url)) {
            item.url = item.url.replace(/^\//, '')
            let route = {
                path: item.url.replace('/', '-'),
                component: null,
                name: item.url.replace('/', '-'),
                meta: {
                    menuId: item.menuId,
                    title: item.name,
                    isDynamic: true,
                    isTab: true,
                    iframeUrl: ''
                }
            }
            // url以http[s]://开头, 通过iframe展示
            if (isURL(item.url)) {
                route['path'] = `i-${item.menuId}`
                route['name'] = `i-${item.menuId}`
                route['meta']['iframeUrl'] = item.url
            } else {
                try {
                    route['component'] = _import(`modules/${item.url}`) || null
                } catch (e) {
                }
            }
            routes.push(route)
        }
    }
    if (temp.length >= 1) {
        fnAddDynamicMenuRoutes(temp, routes)
    } else {
        mainRoutes.name = 'main-dynamic'
        mainRoutes.children = routes
        router.addRoute(mainRoutes)
        router.addRoute({path: '*', redirect: {name: '404'}})
        sessionStorage.setItem('dynamicMenuRoutes', JSON.stringify(mainRoutes.children || '[]'))
        /* eslint-disable */
        console.log('\n')
        console.log('%c!<-------------------- 动态(菜单)路由 s -------------------->', 'color:blue')
        console.log(mainRoutes.children)
        console.log('%c!<-------------------- 动态(菜单)路由 e -------------------->', 'color:blue')
    }
}
