import {ENV, BASE_API_URL} from './constants'

const envMode = process.env.NODE_ENV === ENV.pro ? ENV.pro : ENV.dev;
const apiURL = envMode === ENV.dev ? BASE_API_URL.dev :
    envMode === ENV.pro ? BASE_API_URL.pro : BASE_API_URL.pro;
let config = {
    title: '热作管理与服务系统',
    projectName: "热带作物种质管理与服务系统",
    authorUrlPreFilter: 'statistics',
    env: envMode,
    api: apiURL,
    filePath: apiURL + '/file/',
    iconPath: 'plugins/icons/svg',
    dynamic: {
        // 是否启用动态路由
        enable: true,
        //相对于src/views
        baseFilePath: 'modules',
        basePath: '/module',

    }
};


export default config;
