import Vue from "vue";

import './icons'
import './cookie'
import './element-ui'
import './env'
import './util'
import './config'

// api: https://github.com/axios/axios
import httpRequest from '@/utils/httpRequest'
import { isAuth } from '@/utils'

// 挂载全局
Vue.prototype.$http = httpRequest // ajax请求方法
Vue.prototype.isAuth = isAuth     // 权限方法