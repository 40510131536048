<template>
  <el-tooltip :content="name">
    <svg
        v-if="name"
        :title="title?title:name"
        version="1.1"
        :class="clazz"
        :role="label ? 'img' : 'presentation'"
        :aria-label="label"
        :width="width"
        :height="height"
        :viewBox="box"
        :style="style">
      <path :d="path.d" :fill="path.fill"
            :key="index"
            :stroke="path.stroke"
            v-for="(path,index) in icon.paths"/>
    </svg>
  </el-tooltip>
</template>
<script>
import config from "@/config";

const convert = require('@/assets/js/parse');
const svgPath = config.iconPath
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: false
    },
    scale: [Number, String],
    spin: Boolean,
    flip: {
      validator: function (val) {
        return val === 'horizontal' || val === 'vertical'
      }
    },
    label: String,
    index: String,
    currentIndex: String
  },
  computed: {
    normalizedScale() {
      let scale = this.scale;
      scale = typeof scale === 'undefined' ? 1 : Number(scale);
      if (isNaN(scale) || scale <= 0) {
        // eslint-disable-next-line
        console.warn(`Invalid prop: prop "scale" should be a number over 0.`, this);
        return 1
      }
      return scale
    },
    clazz() {
      return {
        'svg-icon': true,
        spin: this.spin,
        'flip-horizontal': this.flip === 'horizontal',
        'flip-vertical': this.flip === 'vertical',
        active: this.index === this.currentIndex
      }
    },
    icon() {
      let xml = null;
      if (!this.name || this.name === '') {
        return;
      }
      try {
        xml = require(`!xml-loader!@/${svgPath}/icon-${this.name}.svg`);
        const t = xml.svg.$.viewBox.split(' ');
        // eslint-disable-next-line
        console.debug(`src/svg/${this.name}.svg has been loaded`);
        const paths = convert.SVGtoArray(xml.svg);
        return {
          width: t[2],
          height: t[3],
          paths: paths
        }
      } catch (e) {
        // eslint-disable-next-line
        console.log(`${this.name}.svg load error`)
      }
      return {
        width: 0,
        height: 0,
        paths: []
      }
    },
    box() {
      return `0 0 ${this.icon.width} ${this.icon.height}`
    },
    width() {
      return this.icon.width / 112 * this.normalizedScale
    },
    height() {
      return this.icon.height / 112 * this.normalizedScale
    },
    style() {
      if (this.normalizedScale === 1) {
        return false
      }
      return {
        fontSize: this.normalizedScale + 'em'
      }
    }
  },
  register: function () {
    // eslint-disable-next-line
    console.warn("inject deprecated since v1.2.0, SVG files can be loaded directly, so just delete the inject line.")
  },
}
</script>

<style>
.svg-icon {
  display: inline-block;
  fill: currentColor;
}

.svg-icon.flip-horizontal {
  transform: scale(-1, 1);
}

.svg-icon.flip-vertical {
  transform: scale(1, -1);
}

.svg-icon.spin {
  animation: fa-spin 1s 0s infinite linear;
}

@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
